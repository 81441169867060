import * as React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse, isAxiosError } from "axios";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import api from "./api";
import Impact from "./components/impact";
import CoBenefits from "./components/co-benefits";
import ProjectMap from "./components/impact-map";
import ProjectList from "./components/project";
import TransactionLedger from "./components/transaction-ledger";
import {
  ProjectResponse,
  ImpactResponse,
} from "./components/impact-map/map-setup-context/InitialMapValues";
import { TransactionsResponse } from "./components/transaction-ledger/types";
import { FlexColumn } from "./styles";
import SectionErrorComponent from "./components/common/SectionErrorComponent";
import EntryPage from "./EntryPage";
import Loader from "./Loader";

const Container = styled(FlexColumn)`
  flex: 1;

  @media (min-width: 377px) and (max-width: 428px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (min-width: 429px) and (max-width: 1488px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: 1489px) {
    padding-left: 144px;
    padding-right: 144px;
  }
`;

const LoadingContainer = styled(FlexColumn)`
  margin: 20vh 0vw 0vh 0vw;
  justify-content: start;
  align-items: center;
  min-height: 80vh;
  width: 100vw;
`;

function ImpactErrorFallback() {
  return (
    <SectionErrorComponent
      title="Impact to date"
      message="Failed to load the impact of your contributions. If the problem persists, please get in touch with Earthly."
    />
  );
}

function CoBenefitsErrorFallback() {
  return (
    <SectionErrorComponent
      title="Co-benefits"
      message="Failed to load the co-benefits of your contributions. If the problem persists, please get in touch with Earthly."
    />
  );
}

function ImpactMapErrorFallback() {
  return (
    <SectionErrorComponent
      title="Impact Map"
      message="Failed to load the map of your investments. If the problem persists, please get in touch with Earthly."
    />
  );
}

function ProjectsErrorFallback() {
  return (
    <SectionErrorComponent
      title="Supported projects"
      message="Failed to load details about the projects you invested in. If the problem persists, please get in touch with Earthly."
    />
  );
}

function Body({
  loading,
  setLoading,
}: {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) {
  const { clientId } = useParams();
  const { showBoundary } = useErrorBoundary();
  const [quicklyFinishLoading, setQuicklyFinishLoading] = useState(false);

  const [impact, setImpact] = React.useState<ImpactResponse>({
    total: {
      mainImpact: [],
      coBenefits: [],
    },
    perProject: [],
  });

  const [projects, setProjects] = React.useState<ProjectResponse[]>([]);

  const [transactionLedgerList, setTransactionLedgerList] =
    React.useState<TransactionsResponse>([]);
  const [isLedgerVisible, setIsLedgerVisible] = useState(true);
  const [showEntryPage, setshowEntryPage] = useState(false);
  const [errors, setErrors] = useState({
    total: {
      mainImpact: false,
      coBenefits: false,
    },
    perProject: false,
    projects: false,
    impactMap: false,
    transactions: false,
    universal: false,
  });

  useEffect(() => {
    if (!clientId) return;
    const fetchImpact = api
      .getImpact(clientId)
      .then((res: AxiosResponse<ImpactResponse>) => {
        setImpact(res.data);
        if (res.status === 200 && res.data.total.mainImpact.length === 0) {
          setshowEntryPage(true);
        }
      })
      .catch(() => {
        setErrors((prevState) => ({
          ...prevState,
          total: {
            mainImpact: true,
            coBenefits: true,
          },
          perProject: true,
        }));
      });

    const fetchProjects = api
      .getProjects(clientId)
      .then((res: AxiosResponse<ProjectResponse[]>) => {
        const sortedProjects = res.data.sort((a, b) =>
          a.name < b.name ? -1 : 1,
        );
        setProjects(sortedProjects);
      })
      .catch(() => {
        setErrors((prevState) => ({
          ...prevState,
          projects: true,
          impactMap: true,
        }));
      });

    const fetchTransactions = api
      .getTransactions(clientId)
      .then((res: AxiosResponse<TransactionsResponse>) => {
        setTransactionLedgerList(res.data);
        if (res.status === 200 && res.data.length === 0) {
          setshowEntryPage(true);
        }
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (error.response?.status === 403) {
            setIsLedgerVisible(false);
          } else {
            setErrors((prevState) => ({ ...prevState, transactions: true }));
          }
        }
      });
    Promise.allSettled([fetchImpact, fetchProjects, fetchTransactions]).finally(
      () => {
        setQuicklyFinishLoading(true);
      },
    );

    if (
      errors.total.mainImpact &&
      errors.total.coBenefits &&
      (errors.transactions || !isLedgerVisible) &&
      errors.projects &&
      errors.impactMap
    ) {
      setErrors((prevState) => ({ ...prevState, universal: true }));
      showBoundary(
        "Sorry, this page can’t be loaded right now. Our technical team has been automatically notified and will be looking into this.",
      );
    }
  }, [
    clientId,
    errors.total.mainImpact,
    errors.total.coBenefits,
    errors.projects,
    errors.impactMap,
    errors.transactions,
    isLedgerVisible,
    showBoundary,
  ]);

  useEffect(() => {
    if (quicklyFinishLoading) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1900);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [quicklyFinishLoading, setLoading]);

  const mergeImpactDataAndProjectData = projects.map((project) => {
    const projectImpact = impact.perProject.find(
      (impactItem) => impactItem.projectId === project.id,
    );
    return {
      ...project,
      projectId: projectImpact?.projectId ?? "",
      mainImpact: projectImpact?.mainImpact ?? [],
      coBenefits: projectImpact?.coBenefits ?? [],
    };
  });

  if (showEntryPage) {
    return (
      <EntryPage
        message={
          <>
            We haven&apos;t selected which nature projects to invest in just
            yet. You can learn more about the projects on{" "}
            <a href="https://earthly.org/products/buy-now#buy-now">
              Earthly&apos;s website
            </a>
            .
          </>
        }
      />
    );
  }
  if (loading) {
    return (
      <LoadingContainer>
        <Loader quicklyFinishLoading={quicklyFinishLoading} />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      {!errors.universal && (
        <>
          {errors.total.mainImpact && errors.total.coBenefits ? (
            <SectionErrorComponent
              title="Impact to date"
              message="We are having trouble displaying your impact. If the problem persists, please get in touch with Earthly."
            />
          ) : (
            <>
              <ErrorBoundary FallbackComponent={ImpactErrorFallback}>
                <Impact mainImpactList={impact.total.mainImpact} />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={CoBenefitsErrorFallback}>
                <CoBenefits coBenefitList={impact.total.coBenefits} />
              </ErrorBoundary>
            </>
          )}
          {errors.impactMap && errors.projects ? (
            <SectionErrorComponent
              title="Supported projects"
              message="We are having trouble displaying information about the projects you invested in. If the problem persist, please get in touch with Earthly."
            />
          ) : (
            <>
              <ErrorBoundary FallbackComponent={ProjectsErrorFallback}>
                <ProjectList projects={projects} />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={ImpactMapErrorFallback}>
                <ProjectMap projects={mergeImpactDataAndProjectData} />
              </ErrorBoundary>
            </>
          )}
          <TransactionLedger
            transactionLedgerList={transactionLedgerList}
            hasError={errors.transactions}
            isLedgerVisible={isLedgerVisible}
          />
        </>
      )}
    </Container>
  );
}

export default Body;
