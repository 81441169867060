import * as React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FlexColumn, FlexRow, StyledLabelText } from "./styles";
import earthlyLogo from "./assets/footer/earthly-logo.png";
import bCorpBadge from "./assets/footer/b-corp.png";
import trees from "./assets/footer/trees.png";

export const footerHeight = "200px";

const Container = styled.footer`
  height: ${footerHeight};
  padding-left: 144px;
  padding-right: 144px;

  @media (max-width: 1488px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const ContainerInner = styled(FlexRow)`
  height: 100%;
  margin-top: 96px;
`;

const CustomLink = styled.a<{ size: "mobile" | "desktop" }>`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.07em;
  color: #201f1f;
  text-decoration: none;
  padding-bottom: ${(props) => (props.size === "mobile" ? "0px" : "4px")};
  padding-right: ${(props) => (props.size === "mobile" ? "8px" : "0px")};
`;

const LinkContainer = styled(FlexColumn)<{ size: "mobile" | "desktop" }>`
  padding-bottom: ${(props) => (props.size === "mobile" ? "16px" : "0px")};
`;

const LogoContainer = styled(FlexColumn)`
  width: 15%;
  padding-right: 32px;
`;

const LogoContainerMobile = styled(FlexRow)`
  padding-bottom: 16px;
  padding-top: 32px;
`;

const Content = styled(FlexRow)`
  width: 30%;
  padding-right: 16px;
`;

const ContentDesktopInner = styled(FlexColumn)`
  justify-content: space-between;
`;

const BadgeAndRegistrationContainer = styled(FlexRow)`
  padding-bottom: 8px;
`;

const RegistrationText = styled(StyledLabelText)<{
  size: "mobile" | "desktop";
}>`
  align-self: end;
  padding-left: ${(props) => (props.size === "mobile" ? "4px" : "8px")};
`;

const TreeGraphicsContainer = styled(FlexColumn)`
  align-items: end;
  justify-content: flex-end;
  align-content: end;
  width: 55%;
`;

const TreeGraphicsContainerMobile = styled(FlexRow)`
  justify-content: end;
`;

function Links({ size }: { size: "desktop" | "mobile" }) {
  return (
    <LinkContainer size={size}>
      <CustomLink
        size={size}
        href="https://earthly.org/cookie"
        target="_blank"
        rel="noreferrer"
      >
        Cookie Policy
      </CustomLink>
      <CustomLink
        size={size}
        href="https://earthly.org/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </CustomLink>
      <CustomLink
        size={size}
        href="https://earthly.org/earthly-terms"
        target="_blank"
        rel="noreferrer"
      >
        Terms and Conditions
      </CustomLink>
    </LinkContainer>
  );
}

function Footer() {
  const isSmallFooter = useMediaQuery({ query: "(max-width: 991px" });

  if (isSmallFooter) {
    return (
      <Container id="small-footer-container">
        <LogoContainerMobile>
          <a href="https://earthly.org/" target="_blank" rel="noreferrer">
            <img
              id="footer-logo"
              alt="Earthlty logo with green globe"
              src={earthlyLogo}
              width="168px"
            />
          </a>
        </LogoContainerMobile>
        <Links size="mobile" />
        <FlexRow>
          <img
            id="b-corp-badge"
            alt="B corp badge"
            src={bCorpBadge}
            width="32px"
          />
          <RegistrationText size="mobile">
            Earthly is registered in England and Wales (11403131)
          </RegistrationText>
        </FlexRow>
        <TreeGraphicsContainerMobile>
          <img
            id="footer-trees"
            alt="tree graphics in footer"
            src={trees}
            width="552px"
          />
        </TreeGraphicsContainerMobile>
      </Container>
    );
  }

  return (
    <Container id="footer-container">
      <ContainerInner>
        <LogoContainer>
          <a href="https://earthly.org/" target="_blank" rel="noreferrer">
            <img
              id="footer-logo"
              alt="Earthlty logo with green globe"
              src={earthlyLogo}
              width="168px"
            />
          </a>
        </LogoContainer>
        <Content>
          <ContentDesktopInner>
            <Links size="desktop" />
            <BadgeAndRegistrationContainer>
              <FlexRow>
                <img
                  id="b-corp-badge"
                  alt="B corp badge"
                  src={bCorpBadge}
                  width="32px"
                />
                <RegistrationText size="desktop">
                  Earthly is registered in England and Wales (11403131)
                </RegistrationText>
              </FlexRow>
            </BadgeAndRegistrationContainer>
          </ContentDesktopInner>
        </Content>
        <TreeGraphicsContainer>
          <img
            id="footer-trees"
            alt="tree graphics in footer"
            src={trees}
            width="552px"
          />
        </TreeGraphicsContainer>
      </ContainerInner>
    </Container>
  );
}

export default Footer;
