import { rest, RestHandler } from "msw";
import mockProjects from "./mockProjects";
import mockImpact from "./mockImpact";
import mockClient from "./mockClient";
import mockinvestments from "./mockInvestments";

export const fullyQualifiedURL = (path: string) => {
  return new URL(path, import.meta.env.VITE_BACKEND_BASE_URL).toString();
};

const handlers: Array<RestHandler> = [
  rest.get(fullyQualifiedURL("/v3/:clientId"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockClient));
  }),
  rest.get(fullyQualifiedURL("/v3/:clientId/impact"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockImpact));
  }),
  rest.get(fullyQualifiedURL("/v3/:clientId/projects"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProjects));
  }),
  rest.get(fullyQualifiedURL("/v3/:clientId/investments"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockinvestments));
  }),

  rest.post(
    "https://o4507809196802048.ingest.de.sentry.io/api/4507810017050704/envelope/",
    (req, res, ctx) => {
      return res(ctx.status(200));
    },
  ),
];

export default handlers;
