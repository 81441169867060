/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FlexColumn } from "./styles";
import logo from "./assets/Logo_Earthly.png";

const LoaderWrapper = styled(FlexColumn)`
  justify-content: space-between;
  position: relative;
  width: 94%;
  height: 100%;
  padding: 15vh 5vw 0vw 0vh;
  border: none;
`;

const Logo = styled(FlexColumn)`
  position: relative;
  justify-content: center;
  align-items: center;
  background-size: contain;
  border: none;
  width: 100%;
  height: 10vh;
`;

const LoadingProgress = styled(FlexColumn)`
  position: relative;
  justify-content: space-between;
  width: 100%;
  font-size: 2vh;
  font-weight: 500;
  text-align: center;
  margin: 20px 0vw 0vh 0vw;
  padding: 0.5vh;
`;
const LoadingText = styled.span`
  font-size: 3.5rem;
  font-weight: 300;
`;

const ProgressNumber = styled.span`
  position: relative;
  font-size: 2.5rem;
  font-weight: 300;
`;

const ProgressBar = styled.div`
  position: relative;
  width: 90%;
  height: 0.7vh;
  margin: 40px 0vw 0vh 5vw;
  border-radius: 0.5vh;
  overflow: hidden;
`;
const GreyProgressPart = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e7e7e7;
`;
const GreenProgressPart = styled.div<{ progress: number }>`
  position: absolute;
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: #01db8f;
  transition: width 0.3s ease-in-out;
  z-index: 2;
`;
interface Props {
  quicklyFinishLoading: boolean;
}
function Loader({ quicklyFinishLoading }: Props) {
  const [progress, setProgress] = useState(0);

  const progressSteps = [
    1, 2, 4, 6, 7, 9, 12, 15, 17, 19, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33,
    39, 40, 41, 42, 43, 44, 46, 47, 48, 49, 54, 57, 59, 60, 62, 64, 65, 69, 79,
    82, 88, 91, 92, 93, 94, 98, 99,
  ];
  useEffect(() => {
    if (!quicklyFinishLoading) {
      setProgress(0);
      return;
    }

    let step = 0;
    let interval: NodeJS.Timeout | undefined;
    const stepDuration = 10;

    const updateProgress = () => {
      if (step < progressSteps.length) {
        setProgress(progressSteps[step]);
        interval = setTimeout(updateProgress, stepDuration);
        step += 1;
      } else if (interval !== null) clearTimeout(interval);
    };

    updateProgress();
  }, [quicklyFinishLoading]);

  return (
    <LoaderWrapper>
      <Logo>
        <a href="https://earthly.org/" target="_blank" rel="noreferrer">
          <img
            id="footer-logo"
            alt="Earthlty logo with green globe"
            src={logo}
            width="300px"
          />
        </a>
      </Logo>
      <LoadingProgress>
        <LoadingText>Loading</LoadingText>
        <ProgressNumber>{progress}%</ProgressNumber>
      </LoadingProgress>
      <ProgressBar>
        <GreyProgressPart />
        <GreenProgressPart progress={progress} />
      </ProgressBar>
    </LoaderWrapper>
  );
}

export default Loader;
