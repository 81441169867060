import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { FlexColumn, StyledH1 } from "./styles";
import earthlyLogo from "./assets/header/earthly-logo.png";

export const headerHeight = {
  withLogo: 188,
  withoutLogo: 112,
};

const Container = styled(FlexColumn)<{ logoExists: boolean }>`
  background: linear-gradient(184.52deg, #a9c1d5 -69.37%, #d9e8fb 96.33%);
  justify-content: center;
  height: ${(props) =>
    props.logoExists
      ? `${headerHeight.withLogo}px`
      : `${headerHeight.withoutLogo}px`};

  @media (max-width: 1488px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1489px) {
    padding-left: 152px;
    padding-right: 152px;
  }
`;

const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

const LogoAnchor = styled.a<{ logoExists: boolean }>`
  display: flex;
  align-self: center;
  width: 160px;

  @media (max-width: 601px) {
    flex-direction: column;
    align-self: ${(props) => (props.logoExists ? "flex-end" : "center")};
    width: 96px;
  }

  @media (max-width: 428px) {
    margin-bottom: ${(props) => (props.logoExists ? "5px" : "0px")};
  }
`;

const EarthlyLogo = styled.img`
  width: 110px;

  @media (max-width: 601px) {
    width: 66px;
  }
`;

const StyledHeaderContentInner = styled.div`
  display: flex;

  @media (max-width: 428px) {
    flex-direction: column;
    justify-content: start;
  }

  @media (min-width: 429px) {
    align-items: center;
  }
`;

const ClientName = styled(StyledH1)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media (max-width: 428px) {
    font-size: 2.5rem;
  }
`;

const ClientLogo = styled.div`
  @media (max-width: 428px) {
    width: 96px;
    min-width: 96px;
    height: 96px;
    min-height: 96px;
    margin-bottom: 20px;
  }

  @media (min-width: 429px) {
    min-width: 128px;
    width: 128px;
    min-height: 128px;
    height: 128px;
  }

  border-radius: 6px;
  border: 1px solid rgba(90, 90, 90, 0.1);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-right: 16px;
`;

function Header({ clientName, logo }: { clientName: string; logo: string }) {
  return (
    <Container logoExists={!!logo} id="header-container">
      <Helmet>
        <title>
          {clientName ? `${clientName} | Earthly` : "Earthly Dashboard"}
        </title>
      </Helmet>
      <StyledHeaderContent id="header-content">
        <StyledHeaderContentInner>
          {logo && (
            <ClientLogo id="logo-container">
              <img id="logo" alt="company logo" src={logo} />
            </ClientLogo>
          )}
          <ClientName>{clientName}</ClientName>
        </StyledHeaderContentInner>
        <LogoAnchor
          logoExists={!!logo}
          id="earthly-link"
          href="https://earthly.org"
          target="_blank"
          rel="noreferrer"
        >
          <EarthlyLogo id="earthly-logo" alt="earthly logo" src={earthlyLogo} />
        </LogoAnchor>
      </StyledHeaderContent>
    </Container>
  );
}

export default Header;
