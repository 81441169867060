import React from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import {
  FlexColumn,
  StyledBodyText,
  FlexRow,
  StyledBodyTextSmall,
  Digits,
} from "../../styles";
import Icon from "../common/Icon";
import infoIcon from "../../assets/icons/info.png";

const Container = styled(FlexColumn)`
  height: 216px;
  width: 320px;
  border-radius: 16px;
  background: #ffffff;
  padding: 8px 8px;
  justify-content: space-between;
  margin: 8px;
`;

const CardHeader = styled(FlexRow)`
  align-content: start;
  height: 10%;
  min-height: 30px;
  justify-content: space-between;
`;

const CardBody = styled(FlexRow)`
  height: 90%;
`;

const InnerContainer = styled(FlexColumn)`
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
`;

const Volume = styled(Digits)`
  padding-top: 8px;
  font-size: 40px;
  text-align: center;
`;

const TagContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 18px;
`;

const Text = styled(StyledBodyTextSmall)`
  padding: 6px 16px;
`;

const Description = styled(StyledBodyText)`
  padding-bottom: 16px;
  color: #5a5a5a;
`;

const StyledInfoIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  && {
    background-color: white;
    color: black;
    border-radius: 16px;
    font-size: 12px;
`;

export interface Durations {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
}

type Props = Durations & { icon: string; unit: string };

function Tag() {
  return (
    <TagContainer>
      <Text>Social</Text>
    </TagContainer>
  );
}

const getFormattedUnit = (value: number, unit: string) => {
  if (value <= 0) return null;
  const pluralSuffix = value === 1 ? "" : "s";
  return `${value} ${unit}${pluralSuffix}`;
};

const daysPerMonth = 21.667;
const buildTimeComponents = ({
  years,
  months,
  days,
  hours,
  minutes,
}: Omit<Props, "icon" | "unit">) => {
  const totalDays = days + Math.floor(months * daysPerMonth);
  const renderedTimeDisplay = [
    {
      condition: years >= 1,
      firstLine: { value: years, unit: "year" },
      secondLine: { value: totalDays, unit: "day" },
    },
    {
      condition: months >= 1,
      firstLine: { value: months, unit: "month" },
      secondLine: { value: days, unit: "day" },
    },
    {
      condition: days >= 1,
      firstLine: { value: days, unit: "day" },
      secondLine: { value: hours, unit: "hour" },
    },
  ];

  const selected = renderedTimeDisplay.find(({ condition }) => condition) ?? {
    firstLine: { value: hours, unit: "hour" },
    secondLine: { value: minutes, unit: "minute" },
  };

  return [
    getFormattedUnit(selected.firstLine.value, selected.firstLine.unit),
    getFormattedUnit(selected.secondLine.value, selected.secondLine.unit),
  ].filter(Boolean);
};

const renderTimeUnits = (timeUnits: Durations) => {
  const formattedUnits = buildTimeComponents(timeUnits);

  return formattedUnits.map((unitText, index) => (
    <React.Fragment key={unitText}>
      {unitText}
      {index < formattedUnits.length - 1 && <br />}
    </React.Fragment>
  ));
};

function CoBenefitCard({
  unit,
  icon,
  years,
  months,
  days,
  hours,
  minutes,
}: Props) {
  return (
    <Container data-testid={`cobenefit-card-${unit}`}>
      <CardHeader>
        <Tag />
        <StyledInfoIcon
          src={infoIcon}
          alt="Icon"
          data-tooltip-id={`infoicon-${unit}`}
        />
      </CardHeader>
      <CardBody>
        <InnerContainer>
          <Icon icon={icon} description={unit} />
        </InnerContainer>
        <InnerContainer>
          <Volume>
            {renderTimeUnits({ years, months, days, hours, minutes })}
          </Volume>
          <Description>{unit}</Description>
        </InnerContainer>
      </CardBody>
      <StyledTooltip
        id={`infoicon-${unit}`}
        place="top"
        content="Estimated using project documentation and latest reports."
        opacity={1}
        border="1px solid black"
      />
    </Container>
  );
}

export default CoBenefitCard;
