import * as React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios/index";
import { useErrorBoundary } from "react-error-boundary";
import Header from "./Header";
import { FlexColumn } from "./styles";
import Footer from "./Footer";
import Body from "./Body";
import api from "./api";

const StyledContainer = styled(FlexColumn)`
  min-height: 100vh;
`;

type Client = {
  name: string;
  logo: string;
};

function Container() {
  const [client, setClient] = useState<Client | null>(null);
  const [loading, setLoading] = useState(true);

  const { clientId } = useParams();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    if (clientId) {
      const fetchClientDetails = async (): Promise<AxiosResponse<Client>> => {
        const response = await api.getClientDetails(clientId);
        return response;
      };
      fetchClientDetails()
        .then((res) => {
          setClient(res.data);
        })
        .catch(() => {
          showBoundary("Sorry, this client can’t be found.");
        });
    }
  }, [clientId, showBoundary]);

  return (
    <StyledContainer>
      {!loading && (
        <Header clientName={client?.name || ""} logo={client?.logo || ""} />
      )}
      <Body loading={loading} setLoading={setLoading} />

      {!loading && <Footer />}
    </StyledContainer>
  );
}

export default Container;
